<template>
  <div> 
    <source-cont v-if="code" :isDialog="true" :code="code"></source-cont>
  </div>
</template>
<script>
import sourceCont from './sourceCont.vue'
export default {
  name: "sourceWap",
  components: {
    sourceCont
  },
  props:{
  },
  data() {
    return {   
      closeFlag: false,
      code: ''
    };
  },
  created() {
	},
  mounted(){
    let cd = this.$route.query.cd
    if(cd){
      this.code = cd
    }
  },
  methods: {
    initData(code){
      this.code = code
      this.closeFlag = true
    },
    closeModel() {
      this.closeFlag = false
    },
  }
}
</script>


